import React from 'react';


export default class WelcomeAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationBg : '',
      animationText : '',
      userCheck: '',
      animationTextStyle: ''
    }
  }

  componentDidMount(){

    // function readCookie(name) {
    //     var nameEQ = name + "=";
    //     var ca = document.cookie.split(';');
    //     for(var i=0;i < ca.length;i++) {
    //         var c = ca[i];
    //         while (c.charAt(0)==' ') c = c.substring(1,c.length);
    //         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    //     }
    //     return null;
    // }


    if (this.getCookieValue('NS-welcome') === 'returning'){
      this.setState({
        userCheck : 'returning',
        animationTextStyle: 'returning'
      }, function(){
        this.runAnimation('Welcome Back!');
      })
    } else {
      this.createCookie('NS-welcome', 'returning', 7);
      this.setState({ userCheck : ''
      }, function(){
          this.runAnimation('Hi!');
      })
    }
  }

  getCookieValue(a) {
     var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
     return b ? b.pop() : '';
   }

   createCookie(name,value,days) {
     var expires = "";
     if (days) {
         var date = new Date();
         date.setTime(date.getTime() + (days*24*60*60*1000));
         expires = "; expires=" + date.toUTCString();
     }
     document.cookie = name + "=" + value + expires + "; path=/";
   }

  runAnimation(text) {
    const vm = this;

    setTimeout(function(){
      vm.setState({
        animationText : 'bounceInDown',
        welcomeText : text
      });

      setTimeout(function(){
        vm.setState({ animationBg : 'disappear' })
        setTimeout(function(){
          if(document.querySelectorAll('.OverlayView').length > 0 && document.querySelectorAll('.App')[0].length > 0) {
            document.querySelector('.App')[0].removeChild(document.querySelectorAll('.OverlayView')[0])
          }
        }, 500);
      }, 2000);
    }, 500);
  }

  render() {
    return (
      <div className={this.state.animationBg+ ' WelcomeAnimation__bg animated'}>
        <div className={this.state.animationText+ " "+ this.state.animationTextStyle +" WelcomeAnimation__text  animated"}>{this.state.welcomeText}</div>
      </div>
    )
  }
};
