import React from "react";

export default class SecureSources extends React.Component {
  constructor(props) {
    super(props);
    // this.openAccordion = this.openAccordion.bind(this);
  }

  componentDidMount() {
    // document.querySelector('.Carelnk__mobileImg').style.height = window.innerHeight+'px'
  }

  render() {
    return (
      <div
        className="SecureSources"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <div className="SecureSources__text">
          <h1>
            <em>Secure Sources Lite</em>
          </h1>
          <p>
            This is another monorepo that unlike the main secure sources repo
            comes as an all-in-one solution with a few more restrictions. SS
            Lite is ideal for MVP projects that need to get to market ASAP or
            smaller load projects. You can expect to have the basics deployed in
            one day.
          </p>
          <p>
            <em>Technologies & Features</em> <br />
            <br />
            Meteor JS does the heavy lifting but I've included a suite of
            configuration and packages to get you production ready even faster.
            <ul>
              <li>• MongoDB</li>
              <li>• Industry leading account aecurity</li>
              <li>• Strong data typing</li>
              <li>• SMTP</li>
              <li>• Full stack testing</li>
            </ul>
          </p>

          <p>
            <em>Interested?</em> <br />
            <br />
            Access to this repos is available in a few ways:
            <ul>
              <li>• Purchasings github fork access.</li>
              <li>
                • Hiring myself as a consultant to provide on-going support.
              </li>
              <li>
                • Hiring myself as a developer, I charge an upfront fee for the
                development time saved.
              </li>
            </ul>
          </p>
        </div>
      </div>
    );
  }
}
