import React from 'react';

export default class Hamburger extends React.Component {
  render() {
    return (
      <button  className={"Hamburger Hamburger__elastic "+this.props.hamburgerDisplay} type="button" onClick={this.props.toggleNav}>
          <span className="Hamburger__box">
            <div className="Hamburger__mask" data-nav='mobile-menu'></div>
              <span className="Hamburger__inner"></span>
          </span>
      </button>
    )
  }
};
