import React from "react";

export default class Ubisoft extends React.Component {
  constructor(props) {
    super(props);
    // this.openAccordion = this.openAccordion.bind(this);
  }

  componentDidMount() {
    // document.querySelector('.Carelnk__mobileImg').style.height = window.innerHeight+'px'
  }

  render() {
    return (
      <div
        className="Ubisoft"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <div className="Ubisoft__text">
          <h1>
            <em>Ubisoft</em>
          </h1>
          <p></p>

          <p>
            <em>The Project</em> <br />
            <br />
            Think Instagram but for charts and analytics. This project was
            called Metrix it was a enterprise level social media application
            that allowed Ubisoft's 20,000 employees to share and follow popular
            data.
          </p>

          <p>
            After completing the stack, I onboarded all of their HR data and
            then designed a framework to onboard additional datasets with no
            additional development.
          </p>

          <p>
            <em>The Technologies</em> <br />
            Ionic 5, d3, pm2, Serverless, MongoDB, and AWS.
            <br />
          </p>
        </div>
      </div>
    );
  }
}
