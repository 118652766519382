import React from "react";
import Pdf from "../../resume/nathan_sachs_resume.pdf";

export default class WelcomeAnimation extends React.Component {
  render() {
    const email = "me@nathansachs.io";
    return (
      <div className="Contact">
        <span className="Contact__email">{email}</span>
        <div className="Contact__socialWrap">
          <a href={"mailto:me@nathansachs.io?subject=Hello"}>
            <div className="Contact__socialIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                class=""
              >
                <g fill="#ffffff">
                  <path
                    d="M100.344,46.58v44.461c0,2.464-0.877,4.572-2.632,6.327S93.849,100,91.385,100H8.959
 c-2.464,0-4.573-0.877-6.328-2.632S0,93.505,0,91.041V46.58c1.643,1.829,3.527,3.453,5.655,4.872
 c13.514,9.183,22.79,15.622,27.83,19.318c2.128,1.567,3.855,2.79,5.18,3.668c1.325,0.877,3.089,1.772,5.291,2.688
 c2.203,0.914,4.256,1.372,6.16,1.372h0.112c1.904,0,3.957-0.458,6.16-1.372c2.202-0.915,3.966-1.811,5.291-2.688
 c1.325-0.878,3.052-2.101,5.18-3.668c6.347-4.592,15.642-11.031,27.886-19.318C96.872,49.996,98.738,48.372,100.344,46.58z
 M100.344,30.118c0,2.949-0.915,5.768-2.744,8.455c-1.829,2.688-4.105,4.984-6.831,6.887c-14.036,9.743-22.771,15.81-26.206,18.199
 c-0.373,0.261-1.167,0.83-2.38,1.707c-1.213,0.878-2.221,1.587-3.023,2.128c-0.803,0.542-1.773,1.148-2.912,1.82
 s-2.212,1.176-3.22,1.512s-1.94,0.504-2.8,0.504h-0.112c-0.858,0-1.792-0.168-2.8-0.504s-2.081-0.84-3.22-1.512
 s-2.109-1.278-2.912-1.82c-0.803-0.541-1.811-1.25-3.024-2.128c-1.213-0.877-2.006-1.446-2.38-1.707
 c-3.397-2.39-8.287-5.796-14.671-10.22C14.727,49.016,10.9,46.356,9.631,45.46c-2.314-1.568-4.498-3.724-6.551-6.467
 S0,33.701,0,31.35c0-2.912,0.774-5.338,2.324-7.28c1.549-1.941,3.761-2.912,6.636-2.912h82.425c2.427,0,4.526,0.877,6.299,2.632
 C99.457,25.544,100.344,27.654,100.344,30.118z"
                  ></path>
                </g>
              </svg>
            </div>
          </a>
          <a href={Pdf} target="_blank">
            <div className="Contact__socialIcon">CV</div>
          </a>
          {/* <a href="./resume/nathan_sachs_resume.pdf" target="_blank">
            <div className="Contact__socialIcon">CV</div>
          </a> */}
        </div>
      </div>
    );
  }
}
