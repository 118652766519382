import React from "react";

// import {
  // NavLink
// } from "react-router-dom";

export default class Jamieson extends React.Component {
  constructor(props) {
    super(props);
    this.openAccordion = this.openAccordion.bind(this);
  }

  openAccordion(ele){
    if(ele.target.parentNode.className.indexOf("open") !== -1){
      ele.target.parentNode.className = "";
    } else{
      document.querySelectorAll(".Nav ul")[0].className = "";
      ele.target.parentNode.className += " open";
    }
  }

  render() {
    return (
      <div className="Carelnk">
        <h1>USE CAROUSLE HERE</h1>
        <img className="Carelnk__mobileImg" src={require("../../img/jamieson_raw_images/landing-pg.png")}/>
      </div>
    )
  }
};
