import React from 'react';
import './SpecialRequest.css';


export default class SpecialRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadDisplay: '',
      //Images Are Optimized for certain rpms
      images :  [
        { class:"s", src : "s.png", rpm : "35 45 78"},
        { class:"p",  src:"p.png", rpm:"28.75"},
        { class:"e", src:"e.png", rpm:"28.75 57.5"},
        { class:"c", src:"c.png", rpm:"28.75"},
        { class:"i", src:"i.png", rpm:"57.5"},
        { class:"a", src:"a.png", rpm:"28.75"},
        { class:"l", src:"l.png", rpm:"28.75 57.5"},
        { class:"r", src:"r.png", rpm:"28.75"},
        { class:"e-2", src:"e-2.png", rpm:"28.75 57.5 78 45 33"},
        { class:"q", src:"q.png", rpm:"28.75"},
        { class:"u", src:"u.png", rpm:"28.75 57.5"},
        { class:"e-3", src:"e-3.png", rpm:"28.75"},
        { class:"s-s", src:"s-2.png", rpm:"28.75"},
        { class:"t", src:"t.png", rpm:"28.75"},
        { class:"knot", src:"knot.png", rpm:"78"},
      ],
      thumbnails: []
    };

    this.changeImg = this.changeImg.bind(this);
  }

  componentDidMount(){
    let vm = this;
    let spinner = new Image();

    spinner.onload = function () {
      document.querySelectorAll('.SpecialRequest__spinner')[0].setAttribute('src', spinner.src);
      vm.setState({
        loadDisplay: 'hide'
      }, function(){
        vm.buildThumbnails();
      })
    }
    spinner.src = require("./img/s.png");
  }

  buildThumbnails() {
    let thumbnails = [];
    for (var key in this.state.images) {
      thumbnails.push(<img className="SpecialRequest__thumbnail" onClick={this.changeImg} src={require('./img/'+this.state.images[key].src)} alt="" />);
    }

    this.setState({
      thumbnails: thumbnails
    });
  }

  changeImg(input){
    let img = document.querySelectorAll('.SpecialRequest__thumbnailActive');
    let index = 0;
    for( index=0; index < img.length; index++ ) {
      console.log(img)
      img[index].className = 'SpecialRequest__thumbnail';
    }

    input.target.className = 'SpecialRequest__thumbnailActive';
    document.querySelectorAll('.SpecialRequest__spinner')[0].setAttribute('src', input.target.src);
  }

  render() {
    return (
      <div>
        <a href="https://www.youtube.com/watch?v=f9lLAQwKNPQ"  target="_blank" rel="noopener noreferrer" className="SpecialRequest__info">Phenakistoscope designs from Daedelus' Special Re: Quest</a>
        <div className="SpecialRequest__bg"></div>
        <div className={this.state.loadDisplay+" SpecialRequest__loadScreen"}><div className="SpecialRequest__loadText">loading</div></div>
        <img  className="SpecialRequest__spinner" alt="" />
        <div className="SpecialRequest__thumbnailContainer">{this.state.thumbnails}</div>
      </div>
    );
  }
};
