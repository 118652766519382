import React from "react";

export default class Carelnk extends React.Component {
  constructor(props) {
    super(props);
    // this.openAccordion = this.openAccordion.bind(this);
  }

  componentDidMount() {
    // document.querySelector('.Carelnk__mobileImg').style.height = window.innerHeight+'px'
  }

  render() {
    return (
      <div
        className="Carelnk"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <div className="Carelnk__text">
          <h1>
            <em>Carelnk</em>
          </h1>
          <p></p>

          <p>
            <em>The Project</em> <br />
            <br />
            This Meteor JS application allowed patients to communicate directly
            with their healthcare providers. It enabled them to book
            appointments, referrals, and chat directly with their healthcare
            professionals.{" "}
          </p>

          <p>
            <em>The Technologies</em> <br />
            <br />
            Meteor JS, MongoDB, and AWS
          </p>

          <div
            id="bg"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
            }}
          >
            {/* <img src={require("../../img/carelnk.jpg")} /> */}
          </div>
        </div>
      </div>
    );
  }
}
