import React, { useState, useEffect, useRef } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { createApi } from "unsplash-js";
// components
import Logo from "../Logo/Logo";
import WelcomeAnimation from "../WelcomeAnimation/WelcomeAnimation";
import GitHub from "../GitHub/GitHub";
import Hamburger from "../Hamburger/Hamburger";
import Nav from "../Nav/Nav";
import Contact from "../Contact/Contact";

// pages
import Carelnk from "../../pages/Carelnk/Carelnk";
import Groundhogg from "../../pages/Groundhogg/Groundhogg";
import Jamieson from "../../pages/Jamieson/Jamieson";
import SecureSources from "../../pages/SecureSources/SecureSources";
import SecureSourcesLite from "../../pages/SecureSourcesLite/SecureSourcesLite";
import Ubisoft from "../../pages/Ubisoft/Ubisoft";

// Experiments
import SpecialRequest from "../../pages/experiments/SpecialRequest/SpecialRequest";
import Tokyo from "../../pages/experiments/Tokyo/Tokyo";
// import ImageGallery from '../../pages/ImageGallery/ImageGallery';
// import Tetris from '../../pages/experiments/Tetris/Tetris';

const App = ({ location, history }) => {
  const backgroundRef = useRef(null);

  const [overlayView, setOverlayView] = useState(<WelcomeAnimation />);
  const [hamburgerDisplay, setHamburgerDisplay] = useState("");
  const [navType, setNavType] = useState("main");
  const [mandalaDisplay, setMandalaDisplay] = useState("");
  const [backGround, setBackGround] = useState(false);
  const [unsplashImages, setUnsplashImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  const getPhotos = async () => {
    const unsplash = createApi({
      accessKey: "YC_VXFrqWP8mSpfHITgugYjJ97qzFrr8Sc0-JMuilFw",
      // `fetch` options to be sent with every request
      // headers: { "X-Custom-Header": "foo" },
    });

    const results = await unsplash.search.getPhotos({
      query: "software development",
    });
    setUnsplashImages(results.response.results);
    setBackGround(results.response.results[0].urls.raw);
  };
  useEffect(() => {
    // getPhotos();
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      return;
    }
    clearTimeout(intervalId);
    // setIntervalId(
    //   setTimeout(async () => {
    //     if (backgroundRef.current) {
    //       const currentOpacity = parseFloat(
    //         window.getComputedStyle(backgroundRef.current).opacity
    //       );

    //       const imageVisible = currentOpacity === 0.1;

    //       backgroundRef.current.style.opacity = imageVisible ? 0 : 0.1;

    //       setImageIndex(
    //         (imageIndex) => (imageIndex + 1) % unsplashImages.length
    //       );
    //       if (!imageVisible) {
    //         setBackGround(unsplashImages[imageIndex].urls.raw);
    //       }
    //     }
    //   }, 4000)
    // );

    // return () => clearTimeout(intervalId); // Cleanup on component unmount
  }, [unsplashImages, imageIndex, location]); // Empty dependency array ensures the effect runs once on mount

  useEffect(() => {
    if (location.pathname === "/carelnk") {
      setMandalaDisplay("Mandala__hide");
    } else {
      setMandalaDisplay("");
    }
  }, [location.pathname]);

  useEffect(() => {
    setNavType("main");
    setHamburgerDisplay("");
  }, [location]);

  const toggleNav = () => {
    setNavType((prevNavType) =>
      prevNavType === "hamburger" ? "main" : "hamburger"
    );
    setHamburgerDisplay((prevHamburgerDisplay) =>
      prevHamburgerDisplay === "isActive" ? "" : "isActive"
    );
  };

  // if (!backGround) {
  //   return <div />;
  // }

  return (
    <div className="App">
      <NavLink to="/">
        <Logo />
      </NavLink>

      <div className={`HamburgerMenuWrap ${navType}`}>
        <Nav navType={"hamburger"} history={history} />
      </div>
      <div className={`PageRoutes ${navType}`}>
        <Route
          exact
          path="/"
          render={() => (
            <div>
              <Nav navType={navType} history={history} />
              <div className="OverlayView">{overlayView}</div>
            </div>
          )}
        />
        <Route path="/jamieson" component={Jamieson} />
        <Route path="/carelnk" component={Carelnk} />
        <Route path="/groundhogg" component={Groundhogg} />
        <Route path="/github" component={GitHub} />
        <Route path="/tokyo" component={Tokyo} />
        {/*<Route path="/tetris" component={Tetris}/>*/}
        <Route path="/secure-sources" component={SecureSources} />
        <Route path="/ss-lite" component={SecureSourcesLite} />
        <Route path="/ubisoft" component={Ubisoft} />
        <Route path="/special-request" component={SpecialRequest} />
      </div>
      <Contact />
      <Hamburger hamburgerDisplay={hamburgerDisplay} toggleNav={toggleNav} />
      <img
        className={`${mandalaDisplay} Mandala spin animated`}
        src={require("../../img/mandala.png")}
        alt=""
      />
      {location.pathname === "/" && (
        <div
          ref={backgroundRef}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "cover",
            opacity: "0.1",
            transition: "opacity 500ms ease",
            zIndex: "-1",
          }}
        >
          <img
            src={backGround}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      )}

      {/* {backGround} */}
    </div>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(App);
