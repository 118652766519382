import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import ThreeJS from "./components/ThreeJS/";
import registerServiceWorker from "./registerServiceWorker";

import "./index.css";

let msg = "";
msg =
  " Full Stack Engineer ... DevOps Engineer ... Coding Art ... Banjoist ... Are you still reading me? ..." +
  msg;
let position = 0;
function scrolltitle() {
  document.title =
    msg.substring(position, msg.length) + msg.substring(0, position);
  position++;
  if (position > msg.length) position = 0;
  window.setTimeout(() => {
    scrolltitle();
  }, 170);
}
scrolltitle();

ReactDOM.render(
  <BrowserRouter>
    <ThreeJS />
  </BrowserRouter>,
  document.getElementById("reactseed-app")
);
registerServiceWorker();
