import React from 'react';

export default class GitHub extends React.Component {
  render() {
    return (
      <div className="GitHub">
        <a href="https://github.com/nathans7/ProgrammaticAWS" className="GitHub__panel" target="_blank" rel='noopener noreferrer'>
          <div className="GitHub__title">Programmatic AWS</div>
          <div className="GitHub__description">Builds AWS API Gateways and associateds Lambda functions to them with one command.</div>
        </a>
        <a href="https://github.com/nathans7/react-seed" className="GitHub__panel" target="_blank" rel='noopener noreferrer'>
          <div className="GitHub__title">React Seed</div>
          <div className="GitHub__description">Complete React JS starting seed with 1 command.</div>
        </a>
        <a href="https://github.com/nathans7/PushtoS3" className="GitHub__panel" target="_blank" rel='noopener noreferrer'>
          <div className="GitHub__title">Push to S3</div>
          <div className="GitHub__description">A Node tool to build a project and update your S3 bucket.</div>
        </a>
      </div>
    )
  }
};
