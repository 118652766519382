import React from 'react';
import './Tokyo.css';


export default class Tokyo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      flickers: [5, 7, 9, 11, 17, 41, 81, 31, 51],
      flickerInterval: ''
    };
  }

  componentDidMount(){
    let vm = this;
    vm.setState({
      textContainer: document.querySelectorAll('.Tokyo__text')[0],
      letters: document.querySelectorAll('.Tokyo__letter'),
      charCount: document.querySelectorAll('.Tokyo__letter').length
    }, () => {
      vm.animatedFlickers();
    })
  }

  animatedFlickers() {
    let vm = this;
    let letter = vm.state.letters[vm.getRandomNum(0, vm.state.charCount-1)];
    let flickTotal = vm.state.flickers[vm.getRandomNum(0, vm.state.flickers.length-1)];
    let counter = 0;

    vm.state.flickerInterval = setInterval(function(){
      if(letter.className === 'Tokyo__letterOff'){
        letter.className = 'Tokyo__letter';
      } else {
        letter.className = 'Tokyo__letterOff';
      }

      if(counter === flickTotal){
        clearInterval(vm.state.flickerInterval);

        setTimeout(function(){
          vm.animatedFlickers();
        }, vm.getRandomNum(500, 4000));
      }
      counter++
    }, 30);
  }

  getRandomNum(from,to) {
    return Math.floor(Math.random()*(to-from+1)+from);
  }

  render() {
    return (
      <div>
        <img className="Tokyo_bg" src={require('./img/ghost.jpg')} alt="" />
        <div className="Tokyo__text">
          <div className="Tokyo__letter">ア</div>
          <div className="Tokyo__letter">キ</div>
          <div className="Tokyo__letter">ラ</div>
        </div>
      </div>
    );
  }
};
