import React from 'react';

export default class Logo extends React.Component {
  render() {
    return (
      <div className="Logo">
        <div className="Logo__name" onClick={this.props.changeView} data-nav='home'>Nathan Sachs</div>
        <div className="Logo__subtitle">feat. Secure Sources</div>
      </div>
    )
  }
};
