import React from "react";

export default class SecureSources extends React.Component {
  constructor(props) {
    super(props);
    // this.openAccordion = this.openAccordion.bind(this);
  }

  componentDidMount() {
    // document.querySelector('.Carelnk__mobileImg').style.height = window.innerHeight+'px'
  }

  render() {
    return (
      <div
        className="SecureSources"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <div className="SecureSources__text">
          <h1>
            <em>Secure Sources</em>
          </h1>
          <p>
            This is a monorepo that provides numerous solutions front-end and
            back-end solutions. It demonstrates what I've found to be the best
            practices in cloud based software engineering so far. It is a
            evolving project with new sub-repos continuously being added. With
            this repo you can build reliable and secure enterprise level
            solutions.
          </p>

          <p>
            <em>It's biased towards Javascript but TypeScript is an option</em>{" "}
            <br />
            <br />
            TypeScript is great but it's not right for every project. Often
            developers run into scenarios where types are being written just to
            satisfy typescript without adding value. At the beginning of each
            project this will be a top consideration.
          </p>

          <p>
            <em>SQL or NoSQL</em> <br />
            <br />I have no true preference with databases. As many existing
            projects come with an inherited database. My back-end pieces have
            various ORM options to get a handle on all your data.
          </p>

          {/* <p>
            <em>The Repos</em> <br />
            <br />
            Each is fully intergrated and working out of the box, just install,
            make your .env files, and run
            <ul>
              <li>
                • Front-end: React JS, Material UI, SASS, TypeScript, and a lot
                more to be fully secured and integrated with the API
              </li>
              <li>• IaC:</li>
              <li>• Express Back-end:</li>
              <li>
                • SLS Back-end: Serverless & AWS create a complete back-end that
                requires zero VMs or hardware
              </li>
              <li>
                • Crons: Node JS and pm2 provide an alternate and less
                restrictive solution to Serverless crons
              </li>
            </ul>
          </p> */}

          <p>
            <em>This isn't open sourced</em> <br />
            <br />
            Although it's all based on open source frameworks. It's my
            intellectual property that weaves it altogether and demonstrates how
            I can help you skip weeks to months of development the day I'm
            onboarded.
          </p>

          <p>
            <em>Interested?</em> <br />
            <br />
            Access to this repos is available in a few ways:
            <ul>
              <li>
                • Purchasings github fork access to the entire repo or
                individual sub repos.
              </li>
              <li>
                • Hiring myself as a consultant to provide on-going support.
              </li>
              <li>
                • Hiring myself as a developer, I charge an upfront fee for the
                development time saved.
              </li>
            </ul>
          </p>
        </div>
      </div>
    );
  }
}
