import React from "react";


export default class Groundhogg extends React.Component {
  constructor(props) {
    super(props);
    // this.openAccordion = this.openAccordion.bind(this);
  }

  componentDidMount(){
    // document.querySelector('.Carelnk__mobileImg').style.height = window.innerHeight+'px'
  }


  render() {
    return (
      <div className="Groundhogg">
        <div className="Groundhogg__text">
          <h1><em>Groundhogg 3.0</em></h1>
          <p></p>

          <p><em>The Project</em> <br/><br/>I'm currently re-building this popular Worpress CMS alongside a small start-up team. Groundhogg offers a flat fee for a miraid of CMS needs. It will be available for free download with Pro features accessible through a monthly fee.</p>

          <p><em>The Technologies</em> <br/><br/>
          React JS, Wordpress's Gutenberg, and custom data stores similar to those of Redux
          </p>


        </div>
      </div>
    )
  }
};
